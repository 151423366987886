/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'rewind-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.729 5.055a.5.5 0 00-.52.038l-3.5 2.5a.5.5 0 000 .814l3.5 2.5A.5.5 0 008 10.5V8.614l3.21 2.293A.5.5 0 0012 10.5v-5a.5.5 0 00-.79-.407L8 7.386V5.5a.5.5 0 00-.271-.445"/><path pid="1" d="M16 8A8 8 0 110 8a8 8 0 0116 0M1 8a7 7 0 1014 0A7 7 0 001 8"/>',
    },
});
